<template>
  <CCol lg="6">
    <CCard>
      <CCardHeader class="bg-danger text-white"
        ><h3>แบบนัดหมายปรับปรุงพฤติกรรม</h3></CCardHeader
      >
      <form id="formCreateAppointment" v-on:submit.prevent="sendAppointment" method="POST">
        <CCardBody>
          <h3 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            ข้อมูลพื้นฐาน
          </h3>
          <CRow>
            <CCol lg="6">
              <div class="relative w-full mb-3" v-if="userData">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รหัสผู้แจ้ง
                </label>
                <input
                  type="text"
                  name="userId"
                  class="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  readonly
                  v-model="userData.user_id"
                />
              </div>
            </CCol>
            <CCol lg="6" v-if="userData"
              ><div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รหัสโรงเรียน
                </label>
                <input
                  type="text"
                  name="txtSchoolId"
                  class="form-control"
                  readonly
                  :value="userData.user_address"
                /></div
            ></CCol>
            <CCol lg="6">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  วันที่นัดหมาย
                </label>
                <datepicker
                  name="txtDate"
                  input-class="form-control bg-white"
                  :required="true"
                  format="yyyy-MM-dd"
                  placeholder="คลิ๊ก"
                ></datepicker>
              </div>
            </CCol>
            <CCol lg="6">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ภาระกิจ/ คำอธิบายการนัดหมาย
                </label>
                <input
                  type="text"
                  name="txtTask"
                  class="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </CCol>
          </CRow>
          <h3 class="text-gray-500 text-sm mt-3 mb-6 font-bold uppercase">
            ข้อมูลนักเรียน
          </h3>
          <CRow>
            <CCol lg="12">
              <div class="relative w-full mb-3" v-if="studentInfo">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  รายชื่อนักเรียนที่ต้องการมอบหมาย
                </label>
                <v-select
                  multiple
                  :options="studentInfo"
                  v-model="selectedStudent"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="from-control ui-input-text"
                      v-bind="attributes"
                      v-on="events"
                      placeholder="search"
                    />
                  </template>
                </v-select>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter class="text-right">
          <button class="btn btn-success">ส่ง</button>
        </CCardFooter>
      </form>
    </CCard>
  </CCol>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      semesterInfo: null,
      subjectInfo: null,
      studentInfo: null,
      citeriaInfo: null,
      userData: null,
      selectedSemester: null,
      selectedSubject: null,
      selectedStudent: null,
      selectedCiteria: null,
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
    };
  },
  mounted() {
    //SET USER DATA
    this.userData = this.$cookies.get("user");
    //FETCH SEMESTER DATA
    this.axios
      .get(
        this.$hostUrl + "php_action/semesterAPI.php?txtAction=get&qSemester=all"
      )
      .then((response) => {
        this.semesterInfo = response.data.mainData;
        //console.log(this.semesterInfo);
      })
      .finally(() => {});
    //FETCH SUBJECT DATA
    this.axios
      .get(
        this.$hostUrl + "php_action/subjectAPI.php?txtAction=get&qSubject=all"
      )
      .then((response) => {
        this.subjectInfo = response.data.mainData;
        //console.log(response.data);
      })
      .finally(() => {});
    //FETCH STUDENT DATA
    this.axios
      .get(this.$hostUrl + "php_action/userAPI.php?txtAction=get&qStd=all&schoolId="+this.userData.user_address)
      .then((response) => {
        this.studentInfo = response.data.mainData;
        //console.log(response.data);
      })
      .finally(() => {});
    //FETCH CITERIA DATA
    this.axios
      .get(
        this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
      )
      .then((response) => {
        this.citeriaInfo = response.data.mainData;
        if (
          this.userData.username.search("head") >= 0 ||
          this.userData.user_level == "teacher"
        ) {
          this.citeriaInfo = this.citeriaInfo.filter(
            (citeria) => citeria.tag == "class"
          );
        }

        //console.log(this.citeriaInfo);
      })
      .finally(() => {});
  },
  methods: {
    sendAppointment() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateAppointment");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      let jsonArr = JSON.stringify(this.selectedStudent);
      formData.append("selectedStudent", jsonArr);
      this.axios
        .post(this.$hostUrl + "php_action/appointmentAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == null) {
            this.showModal = true;
            this.modalSuccess = true;
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            this.showModal = true;
            this.modalSuccess = false;
            alert(this.responseContent);
            //location.reload();
          }
        })
        .finally(() => {});
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
