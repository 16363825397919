export function currentDate() {
    // Get current date
    const currentDate = new Date();

    // Extract year, month, and day
    const year = currentDate.getFullYear();
    // JavaScript months are zero-based, so we need to add 1 to get the correct month
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // padStart ensures the month is always 2 digits
    const day = String(currentDate.getDate()).padStart(2, '0'); // padStart ensures the day is always 2 digits

    // Format the date as yyyy-mm-dd
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}