<template>
  <div>


    <CCard>
      <CCardBody>
        <CRow>
          <CCol lg="12" v-if="userData">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                เลขประจำตัวนักเรียน:
              </label>
              <input type="text" name="stdId" class="form-control" v-model="stdId" />
            </div>
          </CCol>
          <CCol lg="12" v-if="userData" class="text-right">
            <button type="button" class="btn btn-yellow" @click="fetchStdData">ค้นหา</button>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="12" v-if="stdInfo" class=" mt-3">
            <strong>เลขประจำตัว:</strong> {{ stdInfo.stud_id }}<br />
            <strong>ชื่อ - นามสกุล:</strong> {{ stdInfo.prefix_name }}{{ stdInfo.std_name }} {{ stdInfo.std_surname
            }}<br />
            <strong>ห้อง:</strong> {{ stdInfo.level_abv_name }}/{{ stdInfo.std_class }}<br />
            <CRow>
              <CCol lg="12" class=" mt-3">
                <button type="button" class="btn btn-block btn-black" @click="updateScore">เพิ่มคะแนนเป็น 100
                  คะแนน</button>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>

    </CCard>


  </div>
</template>

<script>

import CardScoringForm from "./components/CardScoringForm";
import CardMakeAppointment from "./components/CardMakAppointment";
import WidgetWorkFromHome from "./widgets/WidgetWorkFromHome";
import WidgetFlag from "./widgets/WidgetFlag.vue";
import TblScreeningLogBySchool from "./components/TblScreeningLogBySchool";
import SltAmphur from "./components/SltAmphur";
import { currentDate } from "../sharedFunctions.js"


export default {
  name: "Dashboard",
  components: {
    CardScoringForm,
    CardMakeAppointment,
    WidgetWorkFromHome,
    WidgetFlag,
    TblScreeningLogBySchool,
    SltAmphur,

  },
  data() {
    return {
      selected: "Month",
      userData: this.$cookies.get("user"),
      stdId: "",
      stdInfo: null,
      currentSemester: null
    };
  },
  mounted() {


    //FETCH SEMESTER DATA
    let formData = new FormData();
    formData.append('txtAction', 'get');
    formData.append('qSemester', 'all');
    this.axios
      .post(
        `${this.$hostUrl}php_action/semesterAPI.php`, formData
      )
      .then((response) => {
        this.currentSemester = response.data.mainData[0].code;
        //console.log(this.currentSemester);
      });


  },
  methods: {
    showAlertFromResponse(title, content, icon, reload) {
      this.$swal.fire({
        title: title,
        text: content,
        icon: icon,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'รับทราบ'
      }).then((result) => {
        if (result.isConfirmed) {
          if (reload == true) {
            location.reload();
          }
        }
      });
    },
    fetchStdData() {
      let formData = new FormData();
      formData.append("txtAction", "getStdData");
      formData.append("stdId", this.stdId);
      formData.append("schoolCode", this.userData.user_address);
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.stdInfo = response.data.mainData;
        });

    },
    updateScore() {
      let formData = new FormData();
      formData.append("txtAction", "loopCreate");
      formData.append("userId", this.userData.user_id);
      formData.append("selectedSemester", this.currentSemester);
      //SUBJECT IS AFFAIR
      formData.append("selectedSubject", 317);
      formData.append("txtDate", currentDate());
      formData.append("txtSchoolId", this.userData.user_address);
      formData.append("stdId", this.stdInfo.stud_id);

      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {

          console.log(response.data);
          if (response.data.createState) {
            this.showAlertFromResponse('สำเร็จ', 'ข้อมูลบันทึกแล้ว', 'success', true);
          } else {
            this.showAlertFromResponse('ผิดพลาด', response.data.error, 'danger', false);
          }

          //this.stdInfo = response.data.mainData;
        });

    }
  },

};
</script>
